import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'
import GalleryComponent from '../components/Gallery'

const IrrigationSystem = (props) => (
  <Layout>
    <Helmet>
      <title>Irrigation System</title>
      <meta
        name="description"
        content="Keeping a garden in good condition. Time, effort, and love are
                  the ingredients for a beautiful garden all year round."
      />
    </Helmet>
    <BannerLanding
      title="Irrigation System"
      descript="Keeping a garden in good condition. Time, effort, and love are the
              ingredients for a beautiful garden all year round."
    />

    <div id="main">
      <section id="one">
        <div className="inner" style={{ overflow: 'hidden' }}>
          <header className="major">
            <h2>Take a look of our work</h2>
          </header>
          <GalleryComponent type="irrigation" />
        </div>
      </section>
    </div>
  </Layout>
)

export default IrrigationSystem
